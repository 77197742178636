.app-container {
    text-align: center;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif;
}

button {
    border: none;
    outline: none;
    width: 100%;
    padding: 15px 0;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
}

.modal-header {
    display: grid!important;
    grid-template-columns: 10fr 1fr;
}