.login {
    width: 100%;
    min-height: 100vh;
    padding: 0 20px;
    background: #e9e9e9;
    display: flex;

    .formContainer {
        padding: 60px;
        margin: auto;
        width: 100%;
        max-width: 520px;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: var(--primary-color);

        h1 {
            color: white;
            font-size: 25px;
            text-align: center;
            margin-bottom: 30px;
        }

        label {
            color: white;
            margin: 14px 0;
            display: block;
            font-size: 20px;
            line-height: 1;
        }

        input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 19px;
            padding: 10px;
            background: white;
            color: black;
            letter-spacing: 1px;
        }

        .btnContainer {
            width: 100%;
            padding: 24px 0;
            text-align: center;
        }
    }

    .message {
        color: white;
        font-size: 16px;
        margin-top: 10px;

        a {
            color: white;
        }
    }
}