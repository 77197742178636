.home {
    width: 100%;
    min-height: 100vh;

    nav {
        background: var(--primary-color);
        width: 100%;
        padding: 20px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            color: white;
            font-size: 20px;
        }

        button {
            width: 100px;
            font-size: 13px;
            margin-top: 5px;
        }

        & > div {
            color: white;
            font-size: 15px;
            display: flex;
            flex-direction: column;
            .user-name {
                font-weight: bold;
            }
        }
    }

    > div {
        .service {
            margin-top: 30px;
            // display: flex;

            .item {
                margin-bottom: 5px;

                select {
                    padding: 8px;
                    margin-left: 10px;
                    border-radius: 5px;
                }
            }

            h2 {
                font-size: 20px;
            }

            .btn {
                width: auto;
                margin-right: 10px;
            }

            label {
                font-weight: bold;
            }

            .service-new-password {
                display: flex;
                align-items: center;

                .new-password {
                    border: 1px solid #ddd;
                    padding: 10px;
                    border-radius: 5px;
                    margin-right: 10px;
                    display: none;
                }
            }
        }
    }

    .passwordContainer {
        display: flex;
        justify-content: space-between;
    }
}

.my-modal {
    .my-modal-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .my-modal-content-view {
            width: 400px;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            padding: 20px;

            .title {
                font-size: 1.2em;
                text-align: center;
                margin-bottom: 20px;
                text-decoration: underline;
                font-weight: 500;
            }

            .item {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                label {
                    width: 70px;
                }
                button {
                    margin-top: 10px;
                }
                button:first-child {
                    margin-right: 10px;
                }
            }


            input {
                width: 100%;
                border: 1px solid #ddd;
                border-radius: 5px;
                padding: 8px;
            }
            select {
                width: 100%;
                padding: 8px;
                border: 1px solid #ddd;
                border-radius: 5px;
            }
        }
    }
}